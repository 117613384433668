import { createAction, props } from '@ngrx/store';
import { IApplyCouponDetail } from '../models/apply-coupon.model';

export const loadApplyCoupon = createAction(
    '[Apply Coupon] Load Apply Coupon',
    props<{ keyword: string }>()
)

export const loadApplyCouponNotMatch = createAction(
    '[Apply Coupon] Load Apply Coupon Not Match',
    props<{ applyCouponStatus: string }>()
)

export const loadApplyCouponDetail = createAction(
    '[Apply Coupon] Load Apply Coupon Detail',
    props<{ couponId: string, applyCouponStatus: string }>()
)

export const loadApplyCouponDetailSuccess = createAction(
    '[Apply Coupon] Load Apply Coupon Detail Success',
    props<{ data: IApplyCouponDetail[], applyCouponStatus: string }>()
)

export const saveApplyCouponKeyword = createAction(
    '[Apply Coupon] Save Apply Coupon Keyword',
    props<{ keyword: string }>()
)

export const clearApplyCoupon = createAction(
    '[Apply Coupon] Clear Apply Coupon'
)

export const clearApplyCouponResult = createAction(
    '[Apply Coupon] Clear Apply Coupon Result'
)